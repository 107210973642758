<template>
	<div class="animated fadeIn">
		<template>
			<b-alert
				show
				variant="secondary">
				<div class="row">
					<div class="col">
						<search
							ref="searchComp"
							v-model="distributor"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor'}"
							:init-value="String(distributor)"
							return-key="id"
							class="mb-3"
							q="label" />
						<div class="row">
							<div class="col">
								<b-button
									:disabled="loading"
									variant="primary"
									class="btn mx-1"
									@click="changeDistributor()">
									{{ translate('search') }}
								</b-button>
								<b-button
									:disabled="loading"
									variant="secondary"
									class="btn mx-1"
									@click="clearDistributor()">
									{{ translate('clear') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-alert>
			<hr>
		</template>
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					class="float-right"
					variant="primary"
					@click="downloadReport">
					<i class="fas fa-file-excel" /> {{ translate('export') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div
					class="row">
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col-md-4 col-sm-6 col-xs-12' ">
						<search
							v-model="filters.id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor'}"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div
						v-if="!isStaff"
						class="col-md-4 col-sm-6 col-xs-12">
						<div
							class="form-group">
							<input-text
								id="full_name"
								:label="translate('full_name')"
								:setter-value="filters.full_name"
								type="text" />
						</div>
					</div>
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col-md-4 col-sm-6 col-xs-12' ">
						<div
							class="form-group">
							<label
								for="status"
								class="label">{{ translate('status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col-md-4 col-sm-6 col-xs-12' ">
						<div class="form-group">
							<label
								for="package"
								class="label">{{ translate('package') }}</label>
							<select
								id="package"
								v-model="filters.package"
								name="package"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="item in packagesData"
									:key="item.id"
									:value="item.attributes.code_name">
									{{ translate(item.attributes.code_name) }}
								</option>
							</select>
						</div>
					</div>
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col-md-4 col-sm-6 col-xs-12' ">
						<div class="form-group">
							<label
								for="permanent_rank"
								class="label">{{ translate('highest_rank') }}</label>
							<select
								id="permanent_rank"
								v-model="filters.permanent_rank"
								name="permanent_rank"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="item in ranksData"
									:key="item.id"
									:value="item.attributes.code_name">
									{{ translate(item.attributes.code_name) }}
								</option>
							</select>
						</div>
					</div>
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col-md-4 col-sm-6 col-xs-12' ">
						<div class="form-group">
							<label
								for="country"
								class="label">{{ translate('country') }}</label>
							<select
								id="country"
								v-model="filters.country"
								:disabled="staffCountry"
								name="country"
								class="form-control"
								@change="getStates">
								<template v-if="!staffCountry">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="countryInfo in countries"
										:key="countryInfo.attributes.code"
										:value="countryInfo.attributes.code">
										{{ translate(countryInfo.attributes.code.toLowerCase()) }}
									</option>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col-md-4 col-sm-6 col-xs-12' ">
						<div class="form-group">
							<label
								for="state"
								class="label">{{ translate('state') }}</label>
							<select
								id="state"
								v-model="filters.state"
								name="state"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="stateInfo in states"
									:key="stateInfo.id"
									:value="stateInfo.id">
									{{ stateInfo.attributes.name }}
								</option>
							</select>
						</div>
					</div>
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col-md-4 col-sm-6 col-xs-12' ">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('join_date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div :class="false && dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col-md-4 col-sm-6 col-xs-12' ">
						<div
							class="form-group">
							<label
								for="filters.dateInfoRange"
								class="label">{{ translate('info_date_range') }}</label>
							<select
								id="dateInfoRange"
								v-model="filters.dateInfoRange"
								name="dateInfoRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateInfoRange in dateRanges"
									:key="dateInfoRange"
									:value="dateInfoRange">
									{{ translate(dateInfoRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="false && dateRange === dateRanges.custom"
						class="col-md-4 col-sm-6 col-xs-12">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div
						v-if="false && dateRange === dateRanges.custom"
						class="col-md-4 col-sm-6 col-xs-12">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0 align-middle">
							#
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('users.id')">
							{{ translate('user_id') }}
							<sort field="users.id" />
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('full_name')">
							{{ translate('name') }}
							<sort field="full_name" />
						</th>
						<th class="border-top-0 align-middle">
							{{ translate('distance') }}
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('product')">
							{{ translate('package') }}
							<sort field="product" />
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('permanent_rank')">
							{{ translate('highest_rank') }}
							<sort field="permanent_rank" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('member_enrollment')">
							{{ translate('new_distributors_enrolled') }}
							<sort field="member_enrollment" />
							<br>
							<small>
								{{ filters.dateInfoRange !== '' ? translate(filters.dateInfoRange) : '' }}
							</small>
						</th>
						<th
							class="border-top-0 pointer">
							{{ translate('unilevel_volume') }}
							<br>
							<small>
								{{ filters.dateInfoRange !== '' ? translate(filters.dateInfoRange) : '' }}
							</small>
						</th>
						<th
							v-if="canViewPersonalInfo"
							class="border-top-0 pointer align-middle"
							@click="sortByField('email')">
							{{ translate('email') }}
							<sort field="email" />
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('sponsor_id')">
							{{ translate('sponsor_id') }}
							<sort field="sponsor_id" />
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('sponsor_username')">
							{{ translate('sponsor_username') }}
							<sort field="sponsor_username" />
						</th>
						<th
							v-if="canViewPersonalInfo"
							class="border-top-0 align-middle">
							{{ translate('mobile_number') }}
						</th>
						<th
							v-if="canViewPersonalInfo"
							class="border-top-0 pointer align-middle"
							@click="sortByField('city')">
							{{ translate('city') }}
							<sort field="city" />
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('state')">
							{{ translate('state') }}
							<sort field="state" />
						</th>
						<th
							v-if="canViewPersonalInfo"
							class="border-top-0 pointer align-middle"
							@click="sortByField('address')">
							{{ translate('address') }}
							<sort field="address" />
						</th>
						<th
							v-if="canViewPersonalInfo"
							class="border-top-0 pointer align-middle"
							@click="sortByField('postcode')">
							{{ translate('postcode') }}
							<sort field="postcode" />
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('country')">
							{{ translate('country') }}
							<sort field="country" />
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('status')">
							{{ translate('status') }}
							<sort field="status" />
						</th>
						<th
							class="border-top-0 pointer align-middle"
							@click="sortByField('created_at')">
							{{ translate('joining_date') }}
							<sort field="created_at" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							<span
								v-if="isStaff"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.id })">
								{{ item.id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.id }}
							</span>
							<span
								v-if="item.attributes.has_ticket && item.attributes.has_enabled_ticket"
								v-b-tooltip.hover
								:title="translate('has_purchased_ticket')"
								style="vertical-align: text-top;margin-left:5px;">
								<i class="fa fa-ticket primary-color" />
							</span>
							<span
								v-if="item.attributes.has_ticket && !item.attributes.has_enabled_ticket"
								v-b-tooltip.hover
								:title="translate('has_pending_ticket')"
								style="vertical-align: text-top;margin-left:5px;">
								<i class="fa fa-ticket text-muted" />
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.level }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.product ? item.attributes.product.toLowerCase() : 'no_package') }}
						</td>
						<td class="align-middle">
							<div class="d-flex align-items-center">
								{{ translate(item.attributes.permanent_rank) }}
								<template v-if="subRankIndicator(item.attributes, 'permanent_sub_rank') !== null">
									<img
										:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(item.attributes, 'permanent_sub_rank')}_indicator.png`"
										class="ml-1 mb-1"
										alt="sub_rank">
								</template>
							</div>
						</td>
						<td class="align-middle">
							{{ item.attributes.member_enrollment }}
						</td>
						<td class="align-middle">
							<b-button
								:id="`popover-volume-${item.id}`"
								class="unilevel-popover"
								variant="link"
								@mouseenter="findVolume(item.id)">
								<i class="fas fa-plus text-primary" />
							</b-button>
							<b-popover
								:target="`popover-volume-${item.id}`"
								triggers="hover"
								custom-class="drb-pbv-popover"
								boundary-padding="30"
								:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'right'">
								<b-alert
									class="p-2 rounded-0 mb-1 border-bottom"
									show
									variant="secondary-darker">
									<div class="row no-gutters">
										<div class="col">
											<h6 class="mb-0">
												{{ translate('unilevel_volume') }}
												<small>
													{{ filters.dateInfoRange !== '' ? '(' + translate(filters.dateInfoRange) + ')' : '' }}
												</small>
											</h6>
										</div>
									</div>
								</b-alert>
								<div class="p-1 pb-2">
									<template v-if="!loadingInfo">
										<div class="pbv-popover">
											<div
												v-if="true"
												class="table-responsive mb-0">
												<table class="table text-nowrap">
													<thead>
														<tr class="text-center">
															<th class="p-2 text-left">
																{{ translate('left') }}
															</th>
															<th class="p-2 text-left">
																{{ translate('right') }}
															</th>
															<th
																class="p-2 text-left align-middle">
																{{ translate('total') }}
															</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td class="p-2 align-middle text-left">
																{{ volumeInfo.left }}
															</td>
															<td class="p-2 align-middle text-left">
																{{ volumeInfo.right }}
															</td>
															<td class="p-2 align-middle text-left">
																{{ volumeInfo.left + volumeInfo.right }}
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</template>
									<is-loading
										:loading="loadingInfo"
										:loading-label="translate('loading')"
										:no-data-label="translate('data_not_found')" />
								</div>
							</b-popover>
						</td>
						<td
							v-if="canViewPersonalInfo"
							class="align-middle">
							{{ item.attributes.email }}
						</td>
						<td class="align-middle">
							<div v-if="!!item.attributes.sponsor_id">
								<span
									v-if="isStaff"
									class="badge badge-primary pointer"
									@click="toDashboard({ distributorId: item.attributes.sponsor_id.toString() })">
									{{ item.attributes.sponsor_id }}
								</span>
								<span
									v-else
									class="badge badge-primary">
									{{ item.attributes.sponsor_id }}
								</span>
							</div>
						</td>
						<td class="align-middle">
							{{ item.attributes.sponsor_username }}
						</td>
						<td
							v-if="canViewPersonalInfo"
							class="align-middle">
							{{ item.attributes.mobile_number }}
						</td>
						<td
							v-if="canViewPersonalInfo"
							class="align-middle">
							{{ item.attributes.city }}
						</td>
						<td class="align-middle">
							{{ item.attributes.state }}
						</td>
						<td
							v-if="canViewPersonalInfo"
							class="align-middle">
							{{ item.attributes.address }}
						</td>
						<td
							v-if="canViewPersonalInfo"
							class="align-middle">
							{{ item.attributes.postcode }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country ? item.attributes.country.toUpperCase() : 'default'}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.joining_date.date).format(dateFormat) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import InputText from '@/components/InputText';

import { PAGINATION } from '@/settings/RequestReply';
import { STATUSES as statuses } from '@/settings/Statuses';
import { admin } from '@/settings/Roles';
import {
	Profile, Network, Tooltip, Packages, Countries as CountriesTrans, Grids, Ranks, Addresses, UnilevelRanks, Events,
} from '@/translations';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';
import SubRanks from '@/mixins/SubRanks';
import DataFilter from '@/components/DataFilter';
import { YMDHMS_FORMAT, DATE_RANGES as dateRanges, V_CALENDAR_CONFIG } from '@/settings/Dates';
import DownlinesInfo from '@/util/DownlinesInfo';
import Country from '@/util/Country';
import GENERAL_INFO from '@/util/GeneralInformation';
import { SEARCH_USERS } from '@/config/endpoint';
import Sponsored from './mixins/Sponsored';
import WindowSizes from '@/mixins/WindowSizes';
import { S3_PATH } from '@/settings/Images';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Downlines',
	messages: [Profile, Network, Tooltip, Packages, CountriesTrans, Grids, Ranks, Addresses, UnilevelRanks, Events],
	components: {
		DataFilter, InputText,
	},
	mixins: [FiltersParams, Sponsored, DashboardRedirect, SubRanks, WindowSizes],
	data() {
		return {
			downlines: new DownlinesInfo(),
			downlinesVolumeInfo: new DownlinesInfo(),
			country: new Country(),
			dateFormat: YMDHMS_FORMAT,
			statuses,
			alert: new this.$Alert(),
			endpoint,
			countries: [],
			states: [],
			dateRanges,
			calendarConfig: V_CALENDAR_CONFIG,
			packages: new GENERAL_INFO(),
			ranks: new GENERAL_INFO(),
			admin,
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
		};
	},
	computed: {
		loading() {
			return !!this.downlines.data.loading;
		},
		loadingInfo() {
			return !!this.downlinesVolumeInfo.data.loading;
		},
		errors() {
			return this.downlines.data.errors;
		},
		pagination() {
			return this.downlines.data.pagination;
		},
		username() {
			try {
				return this.downlines.data.response.data.meta.username;
			} catch (error) {
				return this.$user.details().type;
			}
		},
		data() {
			try {
				const { data } = this.downlines.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		packagesData() {
			try {
				return this.packages.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		ranksData() {
			try {
				return this.ranks.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		staffCountry() {
			if (admin.includes(this.$user.details().type)) {
				return this.$user.details().country !== undefined;
			}
			return false;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
		canViewPersonalInfo() {
			return this.$can('downlines_personal_info', 'view');
		},
		volumeInfo() {
			try {
				return this.downlinesVolumeInfo.data.response.data.response;
			} catch (error) {
				return {};
			}
		},
		isStaff() {
			return this.admin.includes(this.$user.details().type);
		},
	},
	watch: {
		'filters.status': function (value) { // eslint-disable-line func-names
			this.filters.status = typeof value === 'undefined' || value === '' ? undefined : value;
		},
	},
	mounted() {
		this.country.getCountries().then((response) => { this.countries = response; });
		this.searchDistributorsDownlines();
		this.packages.getPackages();
		this.ranks.getRanks();
		if (this.staffCountry) {
			this.filters.country = this.countryCode;
		}
		if (this.filters.dateInfoRange === undefined) {
			this.filters.dateInfoRange = '';
		}
	},
	methods: {
		getStates() {
			const { state, country } = this.filters;
			this.filters.state = this.states.length > 0 ? undefined : state;
			this.states = [];
			if (typeof country !== 'undefined' && country !== '') {
				this.country.getStates(country).then((response) => { this.states = response; });
			}
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.searchDistributorsDownlines(options);
		},
		searchDistributorsDownlines(options) {
			this.getStates();
			let { distributorId } = this.$route.params;
			if (typeof distributorId === 'undefined' && !this.isStaff) {
				distributorId = this.$user.details().id;
			}
			if (typeof distributorId !== 'undefined') {
				this.downlines.getDownlinesReport(distributorId, options);
			}
		},
		downloadReport() {
			const downlines = new DownlinesInfo();
			const loading = new this.$Alert();
			loading.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });

			let { distributorId } = this.$route.params;
			if (typeof distributorId === 'undefined') {
				distributorId = this.$user.details().id;
			}

			downlines.download(distributorId).then(() => {
				const { response } = downlines.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('downlines_information')} - ${this.$moment().format('YYYY-MM-DD')}.xlsx`);
				this.$el.appendChild(link);
				link.click();
				loading.close();
			}).catch(() => {
				loading.close();
			});
		},
		findVolume(userId) {
			this.downlinesVolumeInfo.getBvs(userId);
		},
	},
};
</script>
